<template>
  <div class="_verified">
    <vs-popup class="_verified __popup" button-close-hidden active :title="$t('atencao-0')">
      <div class="flex flex-col gap-4 align-middle justify-center">
        <div class="w-full h-1"/>
        <label :class="['w-full text-center font-bold text-lg', `text-${color}`]">{{ $t('conta-ativada-com-sucesso') }}</label>
        <div class="w-full h-1"/>
        <div class="w-full">
          <vs-button :color="color" class="float-right" @click="procceed">OK</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'success'
    }
  },
  methods: {
    procceed() {
      this.$router.replace('/login')
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
  ._verified.__popup {
    .vs-popup--title h3 {
      color: $success !important;
    }
  }
</style>